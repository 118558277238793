import * as React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import Brisket from '../assets/brisket.webp'
import Tritip from '../assets/tritip.webp'
import PulledPork from '../assets/pulledPork.webp'
import PorkBelly from '../assets/porkBelly.webp'
import ChickenBreast from '../assets/chickenBreast.webp'
import FiletMignon from '../assets/filetMignon.webp'
import Tzatziki from '../assets/tzatziki.webp'
import CabbageSalad from '../assets/cabbageSalad.webp'
import Hummus from '../assets/hummus.webp'
import Rice from '../assets/rice.webp'
import SutDolma from '../assets/veganDolma.webp'
import MedetteraneanDish from '../assets/medetteraneanVeggiesAndChickpeas.webp'
import VeganIcon from "../assets/veganIcon.webp"

export default function MenuPage() {

  return (
    <Box sx={{ pt:'200px', pb: '100px'}}>
      <Box
        sx={{
          textAlign: 'center',
          alignItems:'center',
          justifyContent:'center',
          maxWidth:'1200px',
          m:'auto',
         
        }}
          >
       <Box>
          <Typography fontSize='36px' color='white'>
            Smoked Meats
          </Typography>
        </Box>
          <Grid container px={2}>
            <Grid
                item 
                xs={12} md={6} m='auto' p={2}
                bgcolor='#1a1a1a' borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
            >
                <img src={Brisket} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
            </Grid>
            <Grid
              item 
              xs={12} md={6} p={2}
              bgcolor='#1a1a1a'  borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
            >
              <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                  Brisket
              </Typography>
              <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                  Dry rubbed and slow-smoked for 9-12 hours until the meat melts in your mouth. Comes with delicious sliced brisket flat and chopped brisket point.
              </Typography>
            </Grid>
              <Grid
                item 
                xs={12} md={6}  p={2}
                bgcolor='#2a2a2a'
                sx={{ display: { xs: 'none', md: 'block' } }} 
              >
                <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                  Tritip
                </Typography>
                <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                  Dry rubbed and slow-smoked for 2-3 hours to produce a lightly-smoky, tender, mouth-watering slices of tritip.
                </Typography>
              </Grid>

              <Grid
                  item 
                  xs={12} md={6} p={2}
                  bgcolor='#2a2a2a'
              >
                  <img src={Tritip} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
              </Grid>

              <Grid
                  item 
                  xs={12} md={6} p={2}
                  bgcolor='#2a2a2a'
                  sx={{ display: { xs: 'block', md: 'none' } }}
              >
                  <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                      Tritip
                  </Typography>
                  <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                      Dry rubbed and slow-smoked for 2-3 hours to produce a lightly-smoky, tender, mouth-watering slices of tritip.
                  </Typography>
              </Grid>
              <Grid
                  item 
                  xs={12} md={6} m='auto' p={2}
                  bgcolor='#1a1a1a'  borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 0px'}}
              >
                  <img src={PulledPork} style={{width:'100%', filter:'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
              </Grid>
              <Grid
                item 
                xs={12} md={6} p={2}
                bgcolor='#1a1a1a'  borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 12px 12px'}}
              >
                <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                  Pulled Pork
                </Typography>
                <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                  Dry rubbed pork shoulder, slow-smoked for 8-9 hours and topped with a slightly sweet barbecue sauce. It is cooked until it's tender and pulls apart easier than a fluffy doughnut.
                </Typography>
              </Grid>
          </Grid>
      </Box>
      <Box 
        sx={{
            textAlign: 'center',
            alignItems:'center',
            justifyContent:'center',
            maxWidth:'1200px',
            m:'auto',
            mt:'100px'
        }}
      >
        <Box>
          <Typography fontSize='36px' color='white'>
            Grilled Meats
          </Typography>
        </Box>
        <Grid container px={2} >
          <Grid
            item 
            xs={12} md={6} m='auto' p={2}
            bgcolor='#1a1a1a'  borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
          >
            <img src={FiletMignon} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
          </Grid>
          <Grid
            item 
            xs={12} md={6} p={2}
            bgcolor='#1a1a1a'   borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
          >
            <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
              Filet Mignon
            </Typography>
            <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
              Center cut filets, lightly seasoned and grilled to medium/ medium rare. Tender and juicy.
            </Typography>
          </Grid>
          <Grid
            item 
            xs={12} md={6}  p={2}
            bgcolor='#2a2a2a'
            sx={{ display: { xs: 'none', md: 'block' } }}
          >
            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
              Pork Belly
            </Typography>
            <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
              Fatty pork belly grilled to a crispy exterior, juicy interior and well-rendered melty fat throughout.
            </Typography>
          </Grid>
          <Grid
            item 
            xs={12} md={6} p={2}
            bgcolor='#2a2a2a'
          >
              <img src={PorkBelly} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
          </Grid>
          <Grid
            item 
            xs={12} md={6} p={2}
            bgcolor='#2a2a2a'
            sx={{ display: { xs: 'block', md: 'none' } }}
          >
            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '18px' }} mb={2}>
              Pork Belly
            </Typography>
            <Typography sx={{ color: 'white', textAlign: 'left' }} mb={2}>
              Fatty pork belly grilled to a crispy exterior, juicy interior and well-rendered melty fat throughout.
            </Typography>
          </Grid>
          <Grid
            item 
            xs={12} md={6} m='auto' p={2}
            bgcolor='#1a1a1a' borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 0px'}}
          >
            <img src={ChickenBreast} style={{width:'100%', filter:'drop-shadow(0px 8px 8px black)', borderRadius:'12px'}}/>
          </Grid>
          <Grid
            item 
            xs={12} md={6} p={2}
            bgcolor='#1a1a1a'  borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 12px 12px'}}
          >
            <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
              Chicken Breast
            </Typography>
            <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
              Slightly spicy, marinated chicken breast grilled until tender.
            </Typography>
          </Grid>
        </Grid>
      </Box>

            <Box 
                sx={{
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px'
                }}
            >
                <Box>
                    <Typography fontSize='36px' color='white'>
                        Other Entrees
                    </Typography>
                </Box>

                <Grid container  px={2}>
                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#2a2a2a' borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
                    >
                        <img src={SutDolma} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'  borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Sut Dolma
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Baked rice and herb stuffed sweet peppers.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 12px 12px'}}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'none', md: 'block' }}} 
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Mediterranean Vegetables and Chickpeas
                            </Typography>
                        </Grid>
                        
                        
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Sateed mushrooms, chickpeas,  bell peppers, and zuchini. A rich and flavorful vegan dining option.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 0px 0px'}}
                        bgcolor='#1a1a1a'
                    >
                        <img src={MedetteraneanDish} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 12px 12px'}}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'block', md: 'none' }}} 
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Mediterranean Vegetables and Chickpeas
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Sateed mushrooms, chickpeas,  bell peppers, and zuchini. A rich and flavorful vegan dining option.
                        </Typography>
                    </Grid>
                    
                </Grid>
            </Box>

            <Box 
                sx={{
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px'
                }}
            >
                <Box>
                    <Typography fontSize='36px' color='white'>
                        Sides
                    </Typography>
                </Box>

                <Grid container px={2} >
                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#2a2a2a'  borderRadius={{md:'12px 0px 0px 0px', xs:'12px 12px 0px 0px'}}
                    >
                        <img src={Tzatziki} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a'   borderRadius={{md:'0px 12px 0px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <Typography  mb={2} sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }}>
                            Tzatziki
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            A cool yogurt and cucumber based salad that goes great with almost any meat.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Cabbage Salad
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            A refreshing, slightly tart cabbage and carrot salad.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'
                    >
                        <img src={CabbageSalad} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#1a1a1a'
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                               Cabbage Salad
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            A refreshing, slightly tart cabbage and carrot salad.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} m='auto' p={2}
                        bgcolor='#2a2a2a'
                        //  borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 0px'}}
                    >
                        <img src={Hummus} style={{width:'90%', filter:'drop-shadow(0px 8px 8px black)', borderRadius:'12px'}}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#2a2a2a' 
                        //sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Grid item xs={12}>
                            <img src={VeganIcon} style={{width:'60px', height:'60px'}}/>
                                
                            <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                                Hummus
                            </Typography>
                        </Grid>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                            Creamy, mashed chicked peas that go well as a dip or side.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6}  p={2}
                        bgcolor='#1a1a1a'
                        borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 0px 12px'}}
                        sx={{ display: { xs: 'none', md: 'block' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Rice
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                           Round, fluffy white rice that is a fantastic accompaniment to everything on the menu.
                        </Typography>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={6} p={2}
                        bgcolor='#1a1a1a'
                        borderRadius={{md:'0px 0px 12px 0px', xs:'0px 0px 0px 0px'}}
                    >
                        <img src={Rice} style={{ width: '100%', filter: 'drop-shadow(0px 8px 8px black)', borderRadius:'12px' }}/>
                    </Grid>

                    <Grid
                        item 
                        xs={12} md={12}  p={2}
                        bgcolor='#1a1a1a' borderRadius={{md:'0px 0px 0px 12px', xs:'0px 0px 12px 12px'}}
                        sx={{ display: { xs: 'block', md: 'none' } }}
                    >
                        <Typography  sx={{ color: 'white', fontWeight: 'bold', fontSize: '24px' }} mb={2}>
                            Rice
                        </Typography>
                        <Typography sx={{ color: 'white', textAlign: 'left', fontSize: '20px' }} mb={2}>
                           Round, fluffy white rice that is a fantastic accompaniment to everything on the menu.
                        </Typography>
                    </Grid>
                </Grid>
            </Box>            

    </Box>
  );
}

import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import MenuData from '../../currentMenu.json'
import VendingTable from '../components/schedule/VendingTable';
import { VendingInfo } from '../components/schedule/vendingTypes';
import VendingCards from '../components/schedule/VendingCards';
import dayjs, { Dayjs } from 'dayjs';

const VendingSchedule = ({data}: {data:VendingInfo[]}) => {
 
  return (
    <Box sx={{
      textAlign: 'center',
      alignItems:'center',
      justifyContent:'center',
      maxWidth:'800px',
      m:'auto',
      pt:'200px',
      mb:'50px',
      color: 'white'
    }}>
      <h1>Upcoming Dates</h1>

      <Box display={{xs:'none', md:'block'}}>
        <VendingTable data={data.filter(d => dayjs(d.date).isAfter(dayjs().subtract(1,'day')))} />
      </Box>
      <Box display={{xs:'block', md:'none'}}>
        <VendingCards data={data.filter(d => dayjs(d.date).isAfter(dayjs().subtract(1,'day')))} />
      </Box>
    </Box>
  );
};

export default VendingSchedule;

import React from "react";
import { Box, Typography, IconButton, List, ListItem, Stack } from "@mui/material"
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import Grilling from '../assets/meat-sizzle-desktop-lq.webm'
import GrillingPhone from '../assets/meat-sizzle-mobile-lq.webm'
import './landingPage.css'
import InstagramIcon from '../assets/instagram-logo.webp'
import EmailRestAPI from "../components/EmailRestAPI";
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import CCText from '../assets/cc-text.webp'
import CCTextMobile from '../assets/cc-text-mobile.webp'
import { YelpReviewWidget } from "../components/yelp-reviews/YelpReviewWidget";
import VendingSchedule from "./VendingSchedule";
import vendingSchedule from '../../vendingSchedule.json'
import { NavLink } from "react-router-dom";


const location = `
https://www.google.com/maps/place/34%C2%B009'39.2%22N+118%C2%B017'19.7%22W/@34.160907,-118.2888162,59m/data=!3m1!1e3!4m4!3m3!8m2!3d34.160892!4d-118.288793?entry=ttu&g_ep=EgoyMDI0MDgyOC4wIKXMDSoASAFQAw%3D%3D
`

/*
//// Use to  configure get request for yelp reviews

const options = {
    method: 'GET',
    headers: {
      accept: 'application/json',
      Authorization: 'bearer 4aRZt7u5A8ofCKSpfMxq5D1f3Tn7uPtgsQKKlMkAl_Hc_FHsDUaIL1wsXyc2mOZqGs5c-1n0ZrMlXno6E-S8xZAXtnZpngQTUZraNWUO45PcURdEmmV53Ub5Duh2Z3Yx'
    }
  };
  
  fetch('https://api.yelp.com/v3/businesses/YuzfrLFr4Tnl6nQerMgIEw/reviews?locale=en_US&offset=0&limit=20&sort_by=yelp_sort', options)
    .then(res => res.json())
    .then(res => console.log(res))
    .catch(err => console.error(err));

*/


/*
//// Use to get info about the business

const options = {
  method: 'GET',
  headers: {
    accept: 'application/json',
    Authorization: 'bearer 4aRZt7u5A8ofCKSpfMxq5D1f3Tn7uPtgsQKKlMkAl_Hc_FHsDUaIL1wsXyc2mOZqGs5c-1n0ZrMlXno6E-S8xZAXtnZpngQTUZraNWUO45PcURdEmmV53Ub5Duh2Z3Yx'
  }
};

fetch('https://api.yelp.com/v3/businesses/YuzfrLFr4Tnl6nQerMgIEw?locale=en_US', options)
  .then(res => res.json())
  .then(res => console.log(res))
  .catch(err => console.error(err));

*/


function LandingPage() {

    const [trigger, setTrigger] = React.useState(false)    

    useScrollPosition(({ prevPos, currPos }) => {
        const isShow = currPos.y === 0
        if (!isShow) {
          setTrigger(true)
        } else {
          setTrigger(false)
        }
      }, [])

    return (        
        <Box>
            {/* Section 1: Landing */}            
            <Box sx={{ display: { xs: 'none', md: 'block'}}}>
                <Box 

                    sx={{
                        width:'100%', 
                        position: 'absolute',
                        marginTop: '250px', 
                        transition:'opacity .5s ease-in-out', 
                        opacity: trigger? 0:1, 
                        zIndex: 100,
                        justifyContent:'center', alignItems:'center', textAlign:'center'
                    }}
                >

                    <img src={CCText} 
                        style={{  
                            maxWidth:'50vw', 
                            zIndex: 1,
                            filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))', 
                        }} 
                    />
        
                    <Typography 
                        textAlign='center' 
                        variant="h2" 
                        sx={{ 
                            color: 'white', 
                            fontWeight:'bold', 
                            fontSize: 'clamp(30px, 2vw, 3vw)', 
                            zIndex: 1, 
                            filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))', 
                            mt: '50px'
                        }} 
                    >
                        Savor the flavor
                    </Typography>
                </Box>
                <video src={Grilling} muted controls={false} autoPlay loop style={{ backgroundSize: 'cover', width: '100%', opacity: '80%'  }} /> 
            </Box>               
            <Box sx={{ display: { xs: 'block', md: 'none'}}}>               
                    <Box 
                        sx={{ 
                            position: 'absolute', 
                            marginTop: '250px', 
                            transition:'opacity .5s ease-in-out', 
                            opacity: trigger? 0:1, 
                            zIndex: 100,
                            justifyContent:'center', alignItems:'center', textAlign:'center'
                        }}
                    >

                        <img src={CCTextMobile} style={{  maxWidth:'100%',  zIndex: 1,  filter: 'drop-shadow(0px 0px 3px rgba(0,0,0,1))' }} />

                        <Typography 
                            textAlign='center' 
                            variant="h2" 
                            sx={{ 
                                marginTop:'50px',
                                color: 'white', 
                                fontWeight:'bold', 
                                fontSize: 'clamp(16px, 6vw, 24px)', 
                                zIndex: 1, 
                                filter: 'drop-shadow(0px 0px 2px rgba(0,0,0,1))', 
                            }} 
                        >
                            Savor the flavor
                        </Typography>
                    </Box>
                <video src={GrillingPhone} muted controls={false} autoPlay loop style={{ backgroundSize: 'cover', width: '100%', opacity: '80%'  }} /> 
            </Box>    
            {/* Section 2: Location */}
            <Box 
                sx={{ 
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt:'100px',
                }}
            >                   
                    <YelpReviewWidget/>
            </Box>
            <Box
                sx={{ 
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt: '-100px'
                }}
            >
                <VendingSchedule data={vendingSchedule.schedule.slice(0,5)}/>
            </Box>

            <Box
                sx={{ 
                    textAlign: 'center',
                    alignItems:'center',
                    justifyContent:'center',
                    maxWidth:'1200px',
                    m:'auto',
                    mt: '50px',
                    mb:'100px'
                }}
            >
                <NavLink to={'/VendingSchedule'} className='nav-styles' style={{fontSize:'22px'}}>
                    See our full schedule!
                </NavLink>
                
            </Box>

            <Box>   
                <Typography
                    sx={{
                        mt: '35px',
                        fontSize: '20px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Open for catering and at select breweries: 
                </Typography>
                <Typography
                sx={{
                    mt: '5px',
                    fontSize: '20px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}>
                    Everyday except Tuesday.
                </Typography>    
                <Typography
                sx={{
                    mt: '5px',
                    fontSize: '20px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                    }}
                >
                        
                </Typography>       
                <Typography
                    sx={{
                        mt: '30px',
                        fontSize: '20px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Please give us at least 5 days notice, as all of our food is prepared Fresh! 
                </Typography>     
                <Typography 
                    sx={{
                        mt: '10px',
                        fontSize: '15px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}>
                        Grilled Meats are usually prepared on site.
                    </Typography>
            </Box>
            <Box 
                sx={{ 
                    maxWidth: { xs: '100%', sm: "600px"}, 
                    height: { xs: '100%'}, 
                    bgcolor: '#212021', 
                    mx: 'auto', 
                    borderRadius: '15px',
                    mt: '35px',
                    pt: '15px',
                    pb: '15px'
                }}
            >
                <Typography
                    sx={{
                    
                        fontSize: '16px',
                        color: 'white',
                        textAlign: 'center',
                        mx: 'auto'
                    }}
                >
                    Please text or email us to arrange a call:
                </Typography>
               
                <Typography sx={{
                    textAlign: 'center',
                    mx: 'auto',
                    mt: '15px'
                }}>                        
                    <a 
                        style={{                        
                            color: 'white', 
                            textDecoration: 'none'
                        }} 
                        href="tel:+18182939445"
                    >
                        1 (818) 293 - 9445
                    </a>                                                   
                </Typography>
                <Typography sx={{ 
                    mt: '5px',
                    fontSize: '16px',
                    color: 'white',
                    textAlign: 'center',
                    mx: 'auto'
                }}>
                    charcoalcraze@gmail.com
                </Typography>
            </Box>
            <Box  
                mt={4}
                sx={{ 
                    mx: 'auto', 
                    textAlign: 'center', 
                }}
            >
                <Typography fontSize={32} color='white' mb={4} mt={10}>
                    Follow us on Instagram
                </Typography>

                <IconButton
                  size="large"
                //   onClick={()=>console.log('to instagram')}
                  href='https://www.instagram.com/charcoalcraze/'  rel='norefferer noopener' target="_blank"
                  sx={{
                    
                    transition: "all 0.3s ease-in-out",
                    opacity: 0.7,
                    height: '100px',
                    width: '100px',
                    ":hover": { opacity: 1 },
                  }}
                >
                  <img src={InstagramIcon} style={{height:'100px', width:'100px'}}/>
                </IconButton>
            </Box>
            {/* Section 3: Catering Calendar */}
            <Box>
                <Box id='bookEvent'>
                    <Typography 
                        sx={{ 
                            color: '#FFFFFF', 
                            textAlign: 'center', 
                            mt: '50px', 
                            fontSize: '32px',
                            px: '5%'
                        }}
                    >
                        Want delicious Armenian / American Fusion BBQ catered at your event?
                    </Typography>
                </Box>         
            </Box>
            {/* Section 4: Contact Form */}         
            <Box 
                sx={{ 
                    mx: 'auto', 
                    textAlign: 'center', 
                    mt: '40px'
                }}
            >
                <Typography 
                    sx={{ 
                        fontSize: '28px', 
                        color: 'white'
                    }}
                >
                    Contact Us
                </Typography>    
                <EmailRestAPI />
            </Box> 
            {/* Section 5: Footer */}
            <Box 
                sx={{ 
                    textAlign: 'center',
                    mt: '65px',
                    mb: '25px'
                }}
            >
                <Typography 
                    sx={{ 
                        color: 'white',
                        mx: 'auto'
                    }}
                >
                    Copyright © Charcoal Craze LLC 2024
                </Typography>
                <Typography sx={{ mx: '5px'}}>                        
                    <a 
                        style={{                        
                            color: 'white', 
                            textDecoration: 'none'
                        }} 
                        href="tel:+18182939445"
                    >
                        Phone: +1 818 293 9445
                    </a>                               
                </Typography>
            </Box>
        </Box>
    )
}

export default LandingPage
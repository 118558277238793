import { Counter } from "./features/counter/Counter"
import LandingPage from './pages/LandingPage'
import MenuPage from './pages/MenuPage'
//import StoryMission from './pages/MissionAndStoryPage'
import {ResponsiveAppBar} from './components/Navbar'
import "./App.css"
import { Box } from "@mui/material"
import { Route, Routes } from "react-router-dom"
import { Demodal } from "demodal"
//import CateringPage from "./pages/CateringPage"
import TodaysMenu from "./pages/MenuOfTheDay"
import VendingSchedule from "./pages/VendingSchedule"
import vendingSchedule from '../vendingSchedule.json'

function App() {
  return (
    <Box>
      <ResponsiveAppBar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/Menu" element={<MenuPage />} />
          {/* <Route path="/OurMission" element={<StoryMission />} /> */}
          {/* <Route path="/Catering" element={<CateringPage />} /> */}
          <Route path="/TodaysMenu" element={<TodaysMenu />} />
          <Route path="/VendingSchedule" element={<VendingSchedule data={vendingSchedule.schedule}/>} />
        </Routes>
        <Demodal.Container />
    </Box>
  )
}

export default App
